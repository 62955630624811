import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { retry } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LanguageService } from '@common/language/language.service';
import { WindowRef } from '@common/window-service/window.service';
import { UserService } from '@common/user/user.service';
import { TransferState } from '@angular/platform-browser';
import { AuthServerConfig } from '@common/auth/auth.interfaces';
import { SERVER_AUTH_DATA_STATE } from '@common/start-up/start-up.const';

@Injectable({
  providedIn: 'root'
})
export class LumiWidgetService {
  private apiPath: string = environment.lumi.mainPath;
  private readonly _authConfig: AuthServerConfig = this.transfer.get(SERVER_AUTH_DATA_STATE, null);

  constructor(
    private readonly http: HttpClient,
    private readonly languageService: LanguageService,
    private readonly windowRef: WindowRef,
    private readonly userService: UserService,
    private readonly transfer: TransferState,
  ) { }

  public getLumiToken$(): any {
    const headers = {
      'Authorization': this._authConfig.lumiPartnerKey,
      'langId': '1', // NOSONAR
    };
    const userEmail: string = this.userService.userEmail;

    return this.http.post(
      `${this.apiPath}/allyz/mds/api/partner/v1.0/memberauthentication`,
      { login: userEmail }, { headers: headers })
      .pipe(
        retry(2),
      )
  }

  public initWidget(name: string, wrapper: string, token: any): void {
    const languageCode: string =  this.languageService.languageCode;
    const countryCode: string = this.languageService.countryCode;
    const config = {
      elementID: wrapper,
      userAccessToken: token,
      partnerToken: this._authConfig.lumiPartnerKey,
      lumiWidgetConfig: {
        userAccessServices: {
          SYMPTOM_CHECKER: true,
          TELECONSULTATION: true,
          MEDICATION_DELIVERY: true,
          APPOINTMENT_BOOKING: true,
          DOCTOR_CHAT: true,
          SMO: true,
        },
        screenRedirect: name,
        screenParams: {},
        partnerCountry: countryCode.toLowerCase(),
        language: `${languageCode.toLowerCase()}-${languageCode.toUpperCase()}`,
        partnerPlatform: 'Android',
        encKey: token.aesKey,
        isEmbeddedWidget: true,
        drChatUserDataNickName: "Allyz User",
        drChatHideSupportButton: true,
        drChatTitle: "Dr. Chat",
      }
    }

    // @ts-ignore
    this.windowRef.nativeWindow.window.lumi.initWidget(config);
  }
}
