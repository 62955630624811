import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { filter, from, map, Observable, of, Subject, switchMap, take, takeUntil } from 'rxjs';
import { getAge } from '@kit/utils/date.utils';
import { IS_BROWSER_PLATFORM } from '@kit/utils/ssr.utils';
import { LanguageService } from '../language/language.service';
import { ServicePartner, UserProfile } from '../profile/profile.interfaces';
import { UserService } from '../user/user.service';
import { WindowRef } from '../window-service/window.service';
import {
  DigitalData,
  Environment,
  EventDigitalData,
  Notification,
  NotificationMethod,
  Page,
  PageInfo,
  Process,
  User
} from './digital-data.interface';
import { encodeStringToSHA256 } from './encode.helper';
import { ConsentType } from '@pages/account/components/notification/notification-options.consts';
import { getCountryCode } from '@common/language/language.const';
import { environment } from '../../../environments/environment';

const DEFAULT_PAGE = "Travel-Home";
const DEFAULT_SITE_SECTION_NAME = "Travel-Home";

function getMethod(email: boolean, sms: boolean): NotificationMethod {
  switch (true) {
    case email && sms: return NotificationMethod.BOTH;
    case email: return NotificationMethod.EMAIL;
    case sms: return NotificationMethod.SMS;

    default: return NotificationMethod.NONE;
  }
}

@Injectable({
  providedIn: 'root'
})
export class DigitalDataService implements OnDestroy {
  private previousPage: string;
  private onDestroy$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private titleService: Title,
    private languageService: LanguageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private windowRef: WindowRef,
    @Inject(IS_BROWSER_PLATFORM)
    private readonly isBrowser: boolean
  ) {
    if (this.isBrowser) this.listenPreviousPage();
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public addEvent(event: EventDigitalData): void {
    if (this.windowRef.nativeWindow?.digitalData) {
      this.windowRef.nativeWindow.digitalData.event = event;
    }
  }

  public updateDigitalData(): void {
    if (!this.windowRef.nativeWindow) {
      return;
    }

    this.userService.userData$
      .pipe(
        switchMap((userData: UserProfile) => this.encodeEmailInUserData(userData)),
        take(1),
      )
      .subscribe((userData: UserProfile) => {
        const digitalData: DigitalData = {
          page: this.getPageInfo(),
          user: this.getUserInfo(userData),
          environment: this.getEnvironmentInfo(),
        };

        if (this.userService.userNotification$.value) {
          digitalData.notifications = this.getNotification();
        }

        if (userData) {
          digitalData.process = this.getLegalAcceptance(userData);
        }

        this.windowRef.nativeWindow.digitalData = digitalData;

        if (this.windowRef.nativeWindow.s) {
          this.windowRef.nativeWindow.s.pageName = digitalData.page.pageInfo.pageName;
          this.windowRef.nativeWindow.s.pageUrl = digitalData.page.pageInfo.URL;
        }
      });
  }

  public updatePageName(pageName: string): void {
    if (!this.isBrowser || !this.windowRef.nativeWindow?.digitalData) {
      return;
    }

    try {
      const pageInfo: PageInfo = {
        ...this.getPageInfo().pageInfo,
        pageName: pageName,
      };

      this.windowRef.nativeWindow.digitalData.page.pageInfo = pageInfo;

      if (this.windowRef.nativeWindow.s) {
        this.windowRef.nativeWindow.s.pageName = pageName;
      }
    } catch { }
  }

  public getPageName(): string {
    const countryCode: string = getCountryCode(this.document.location?.host);
    let paths: UrlSegment[] = this.activatedRoute.firstChild?.snapshot?.url || [];
    paths.reverse();

    //Visa
    if (paths.length > 0) {
      paths = paths.filter((path: UrlSegment) => path.path !== 'en-fr');
    }

    if (paths?.length && paths[1]?.path === 'planificateur-voyage') {
      return `${paths[1].path}/${paths[0].path}`;
    }

    if (paths?.length) {
      return isNaN(Number(paths[0].path)) ? paths[0].path : paths[1].path;
    }

    return `${DEFAULT_PAGE}-${countryCode}`;
  }

  private getSiteSectionName(): string {
    const sectionName: string = this.document.location.pathname.split('/')[1];

    //Visa
    if (sectionName === 'en-fr') return DEFAULT_SITE_SECTION_NAME

    return sectionName || DEFAULT_SITE_SECTION_NAME
  }

  public getDigitalData(): DigitalData {
    return this.windowRef.nativeWindow.digitalData;
  }

  public putDigitalData(digitalData: DigitalData): void {
    this.windowRef.nativeWindow.digitalData = digitalData;
  }

  private getPageInfo(): Page {
    const pageInfo: Page = {
      pageInfo: {
        language: this.languageService.languageCode.toLowerCase(),
        URL: this.document.location.origin + this.document.location.pathname,
        fullURL: this.document.location.href,
        siteSection: this.getSiteSectionName(),
        pageName: this.getPageName(),
        title: this.titleService.getTitle(),
      }
    };

    if (this.previousPage) {
      pageInfo.pageInfo.previousPage = this.previousPage;
    }

    if (!this.previousPage && this.document.referrer && !this.document.referrer.startsWith(this.document.location.origin)) {
      pageInfo.pageInfo.referringURL = this.document.referrer;
    }

    return pageInfo;
  }

  private getUserInfo(userData: UserProfile): User {
    const user: User = <User>{
      login: {
        isLoggedIn: 'false',
      },
      membershipStatus: this.userService.userSubscription$.value,
    };

    if (this.userService.authorized && userData) {
      user.login.isLoggedIn = 'true';
      user.login.identificationMethod = userData.identificationMethod || '';
      user.login.firstLoginDate = userData.firstAccess || '';
      user.login.lastLoginDate = userData.lastAccess || '';
      user.login.dateOfAccountCreation = userData.created || '';

      user.profileId = userData.id?.toString();
      user.membershipDuration = userData.membershipDuration?.toString();
      user.voucherCode = userData.promoCode || '';
      user.email = userData.email || '';

      if (userData.dateOfBirth) {
        user.dob = userData.dateOfBirth;
        user.age = `${getAge(userData.dateOfBirth)}`;
      }

      user.country = userData.residence || '';
      user.language = userData.language || '';

      user.noOfCoTravellers = userData.noOfCoTravellers;
      user.consent = {
        preferredCommunication: this.preferredCommunication(),
      }
      user.type = this.getUserType();
    }

    return user;
  }

  private getEnvironmentInfo(): Environment {
    const appWindow = this.windowRef.nativeWindow;
    const userOsInfo = this.getUserOSInfo();

    return {
      applicationId: '1',
      applicationName: 'AllyzTravelWebApp',
      applicationVersion: '4.4.0',
      clientOs: userOsInfo.name,
      clientOsVersion: userOsInfo.version,
      name: environment.name,
      screenResolution: `${appWindow.screen.width}X${appWindow.screen.height}`,
      type: this.isWebView() ? 'mobileAppWebView' : 'webApp',
      viewportResolution: `${appWindow.innerWidth}X${appWindow.innerHeight}`,
    }
  }

  private getNotification(): Notification[] {
    const notification = this.userService.userNotification$.getValue();
    const flightNotification = notification.filter(notification => notification.partnerName === ServicePartner.AMADEUS);
    const flightNotificationEmail = flightNotification.find(notification => notification.consentType === ConsentType.EMAIL);
    const flightNotificationSms = flightNotification.find(notification => notification.consentType === ConsentType.SMS);

    return [{
      serviceInfo: {
        serviceName: 'flight',
        serviceMethod: getMethod(flightNotificationEmail.consentValue, flightNotificationSms.consentValue),
      }
    },
    {
      serviceInfo: {
        serviceName: 'compensation',
        serviceMethod: NotificationMethod.NONE, // TODO
      }
    },
    {
      serviceInfo: {
        serviceName: 'safety',
        serviceMethod: NotificationMethod.NONE, // TODO
      }
    }];
  }

  private preferredCommunication(): string {
    const notification = this.userService.userNotification$.value;
    const emailResult = notification?.find(notification => {
      return notification.consentType === ConsentType.GLOBAL_MARKETING
        || notification.consentType === ConsentType.INTERNAL_MARKETING
        || notification.consentType === ConsentType.EXTERNAL_MARKETING;
    }).consentValue;

    let result = '';
    result += `email:${!!emailResult}`;
    result += `|mail:false`; // TODO
    result += `|phone:false`; // TODO
    result += `|mobile:false`; // TODO

    return result;
  }

  private getLegalAcceptance(userData: UserProfile): Process {
    return {
      legalAcceptance: {
        isElectronicCommunicationChecked: userData.marketingConsent ? 'true' : 'false',
        isPreContractualInformationChecked:  'true',
        isTermsAndConditionsChecked: 'true',
      }
    }
  }

  private listenPreviousPage(): void {
    let currentPage: string = null;
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.onDestroy$),
    ).subscribe((event) => {
      if (currentPage) {
        this.previousPage = this.document.location.origin + currentPage;
      }

      currentPage = (<NavigationEnd>event).url;
    });
  }

  private encodeEmailInUserData(userData: UserProfile): Observable<UserProfile> {
    if (userData?.email) {
      return from(encodeStringToSHA256(userData.email)).pipe(
        map((email: string) => ({...userData, email}))
      )
    }

    return of(userData);
  }

  private getUserOSInfo(): {name: string, version: string} {
    const userAgent: string =  navigator.userAgent || navigator.vendor;

    if (/Windows/.test(userAgent)) {
      const match: RegExpExecArray = /Windows NT (\d+\.\d+)/.exec(userAgent);

      return { name: 'Windows', version: match ? match[1] : 'Unknown' };
    }
    if (/Android/.test(userAgent)) {
      const match: RegExpExecArray = /Android (\d+(\.\d+)?)/.exec(userAgent);

      return { name: 'Android', version: match ? match[1] : 'Unknown' };
    }
    if (/(iPhone|iPod|iPad|iOS)/.test(userAgent)) {
      const match: RegExpExecArray = /OS (\d+(_\d+)?)/.exec(userAgent);

      return { name: 'iOS', version: match ? match[1].replace('_', '.') : 'Unknown' };
    }
    if (/Mac OS|Macintosh/.test(userAgent)) {
      const match: RegExpExecArray = /Mac OS X (\d+[\._]\d+)/.exec(userAgent);

      return { name: 'Mac OS', version: match ? match[1].replace('_', '.') : 'Unknown' };
    }
    if (/Linux/.test(userAgent)) {
      const match: RegExpMatchArray = userAgent.match(/(Ubuntu|Fedora|Red Hat|SuSE|Debian|Gentoo|Arch)\/([\d.]+)/)
      return match
        ? { name: match[1] || 'Linux', version: match[2] || 'Unknown'}
        : { name: 'Linux', version: 'Unknown' };
    }

    return { name: 'Unknown', version: 'Unknown' };
  }

  private isWebView(): boolean {
    const userAgent: string = navigator.userAgent || navigator.vendor;
    const normalizedUserAgent: string = userAgent.toLowerCase();

    const isIos: boolean = /ip(ad|hone|od)/.test(normalizedUserAgent) || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;
    const isAndroid: boolean = /android/.test(normalizedUserAgent);
    const isSafari: boolean = /safari/.test(normalizedUserAgent);

    return (isAndroid && /; wv\)/.test(normalizedUserAgent)) || (isIos && !isSafari);
  }

  private getUserType(): string {
    if (
      environment.name !== 'PROD' ||
      this.userService.adminScopeAvailable$.value ||
      this.userService.superAdminScopeAvailable$.value
    ) {
      return 'Internal';
    }

    return 'External';
  }
}
